import React from "react"
import { addComma } from "../../../../../utils/add-comma"
import { AlertBanner } from "../../../../alert-banner"
import AlertMessage from "../../../../alert-message"
import { useCustomerContext } from "../store"
import { useResourceStringsNewContracts } from "./new-contracts/use-resource-strings"
import { useMsal } from "@azure/msal-react"
import { NewContractButton } from "../../../../new-contract-button"
import { HireInsuranceStatus } from "../../../../hire-insurance-status"
import { NewSiteSurveyButton } from "../../../../new-site-survey-button"

export const CxCustomerDetail = () => {
  const { customer, isCustomerLoading } = useCustomerContext()
  const { instance, accounts } = useMsal()
  const {
    addressName,
    addressLine1,
    addressLine2,
    addressLine3,
    addressTown,
    addressCounty,
    addressPostcode,
    customerName,
    accountNo,
    mainPhone,
    customerEmailAddress,
    creditLimit,
    balance,
    paymentTerms,
  } = customer || {}

  const formattedAddress = customer
    ? `${addComma(addressName)} ${addComma(addressLine1)}${addComma(
        addressLine2
      )}${addComma(addressLine3)}${addComma(addressTown)}${addComma(
        addressCounty
      )} ${addressPostcode || ""}`
    : ""

  if (isCustomerLoading) {
    return (
      <div className="flex items-center h-full pl-44">
        <p className="text-xl animate-pulse">Loading...</p>
      </div>
    )
  }

  const { alertOnHold } = useResourceStringsNewContracts()

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  });

  const parseValueAsCurrency = (value: string) => {
    const parsedValue = parseFloat(value)
    if (!isNaN(parsedValue)) {
      return formatter.format(parsedValue)
    }

    return "Not available"
  }

  return (
    <div>
      {customer ? (
        <>
          <div className="flex flex-row flex-wrap justify-between items-center">
            <h1 className="font-bold uppercase text-[45px]">{customerName}</h1>
            <AlertMessage mode="danger" messages={[alertOnHold]} show={customer?.isOnHold == "true"}/>
          </div>
          <div className="flex flex-row">
            <p className="flex-1 uppercase text-[22px]">
              account no. <span className="font-bold">{accountNo}</span>
            </p>
            <NewSiteSurveyButton 
              accounts={accounts}
              customer={customer}
              instance={instance}
            />
            <NewContractButton 
              accounts={accounts}
              customer={customer}
              instance={instance}
            />
          </div>
          <hr className="my-[30px] w-full bg-black h-[2px] opacity-25 b-none" />

          <div className="flex flex-row w-full gap-24">
            <div className="flex flex-col flex-grow max-w-[50%]">
              <h2 className="uppercase text-[27px] font-bold mb-6">
                Contact Details
              </h2>
            </div>
            <div className="flex flex-col">
              <h2 className="uppercase text-[27px] font-bold mb-6">
                Account Details
              </h2>
            </div>
          </div>
          <div className="flex flex-row w-full gap-24">
            <div className="flex flex-col flex-grow max-w-[50%]">
              <h3 className="mb-2 text-xl font-bold uppercase">Address</h3>
              <p className="mb-8">{formattedAddress}</p>
            </div>
            <div className="flex flex-col">
              <h3 className="mb-2 text-xl font-bold uppercase">Payment Terms</h3>
              <p className="mb-8">{paymentTerms}</p>
            </div>
          </div>
          <div className="flex flex-row w-full gap-24">
            <div className="flex flex-col flex-grow max-w-[50%]">
              <h3 className="mb-2 text-xl font-bold uppercase">Telephone No</h3>
              <p className="mb-8">
                <a href={`tel:${mainPhone?.replace(" ", "")}`}>{mainPhone}</a>
              </p>
            </div>
            <div className="flex flex-col">
              <h3 className="mb-2 text-xl font-bold uppercase">Credit Limit</h3>
              <p className="mb-8">{parseValueAsCurrency(creditLimit)}</p>
            </div>
          </div>
          <div className="flex flex-row w-full gap-24">
            <div className="flex flex-col flex-grow max-w-[50%]">
              <h3 className="mb-2 text-xl font-bold uppercase">Email Address</h3>
              <p className="mb-8">
                <a className="underline" href={`mailto:${customerEmailAddress}`}>
                  {customerEmailAddress}
                </a>
              </p>
            </div>
            <div className="flex flex-col">
              <h3 className="mb-2 text-xl font-bold uppercase">A/C Balance</h3>
              <p className="mb-8">{parseValueAsCurrency(balance)}</p>
            </div>
          </div>
        </>
      ) : (
        <h1>No Customer Found</h1>
      )}
      <HireInsuranceStatus
        hasInsurance={customer?.hasInsurance}
        insuranceRenewalDate={customer?.insuranceRenewalDate}
        classNames="mb-8"
      />
      {customer?.alertText && <AlertBanner text={customer?.alertText} />}
    </div>
  )
}
